import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import Layout from "../components/layout";

const Privacy = () => {
  return (
    <Layout title={`Privacy`}>
      <Container>
        <Row>
          <Col className="mt-5">
            <h1>Privacy Policy</h1>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 mb-5">
            <p>
              This Privacy Policy governs the manner in which BusinessPro
              collects, uses, maintains and discloses information collected from
              users (each, a “User”) of the https://businesspro.co website
              (“Site”).This privacy policy applies to the Site and all products
              and services offered by BusinessPro. Personal identification
              information We may collect personal identification information
              from Users in a variety of ways in connection with activities,
              services, features or resources we make available on our Site..
              Users may visit our Site anonymously. We will collect personal
              identification information from Users only if they voluntarily
              submit such information to us. Users can always refuse to supply
              personally identification information, except that it may prevent
              them from engaging in certain Site related activities.
              Non-personal identification information We may collect
              non-personal identification information about Users whenever they
              interact with our Site. Non-personal identification information
              may include the browser name, the type of computer and technical
              information about Users means of connection to our Site, such as
              the operating system and the Internet service providers utilized
              and other similar information. Web browser cookies Our Site may
              use “cookies” to enhance User experience. User’s web browser
              places cookies on their hard drive for record-keeping purposes and
              sometimes to track information about them. User may choose to set
              their web browser to refuse cookies, or to alert you when cookies
              are being sent. If they do so, note that some parts of the Site
              may not function properly. How we use collected information
              BusinessPro may collect and use Users personal information for the
              following purposes: – To improve customer service Information you
              provide helps us respond to your customer service requests and
              support needs more efficiently. – To personalize user experience
              We may use information in the aggregate to understand how our
              Users as a group use the services and resources provided on our
              Site. – To improve our Site We may use feedback you provide to
              improve our products and services. How we protect your information
              We adopt appropriate data collection, storage and processing
              practices and security measures to protect against unauthorized
              access, alteration, disclosure or destruction of your personal
              information, username, password, transaction information and data
              stored on our Site. Sharing your personal information We do not
              sell, trade, or rent Users personal identification information to
              others. We may share generic aggregated demographic information
              not linked to any personal identification information regarding
              visitors and users with our business partners, trusted affiliates
              and advertisers for the purposes outlined above. Third party
              websites Users may find advertising or other content on our Site
              that link to the sites and services of our partners, suppliers,
              advertisers, sponsors, licensors and other third parties. We do
              not control the content or links that appear on these sites and
              are not responsible for the practices employed by websites linked
              to or from our Site. In addition, these sites or services,
              including their content and links, may be constantly changing.
              These sites and services may have their own privacy policies and
              customer service policies. Browsing and interaction on any other
              website, including websites which have a link to our Site, is
              subject to that website’s own terms and policies. Changes to this
              privacy policy BusinessPro has the discretion to update this
              privacy policy at any time. When we do, we will revise the updated
              date at the bottom of this page. We encourage Users to frequently
              check this page for any changes to stay informed about how we are
              helping to protect the personal information we collect. You
              acknowledge and agree that it is your responsibility to review
              this privacy policy periodically and become aware of
              modifications. Your acceptance of these terms By using this Site,
              you signify your acceptance of this policy and terms of service.
              If you do not agree to this policy, please do not use our Site.
              Your continued use of the Site following the posting of changes to
              this policy will be deemed your acceptance of those changes.
              Contacting us If you have any questions about this Privacy Policy,
              the practices of this site, or your dealings with this site,
              please contact us at: BusinessPro https://businesspro.co
              privacy@businesspro.co This document was last updated on May 09,
              2013
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Privacy;
